.DocumentsList {
  &__header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

	&__modal {
		width: 800px;
		height: auto;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		img {
			width: 100%;
			height: auto;
			display: block;
		}
  }
}